import React from "react";
import styled from "styled-components";
import { ToggleGroup, Toggle } from "/components/Controls";

const Wrapper = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Type = styled(props => (
  <div {...props}>
    <h2>{props.name}</h2>
    <small>Tesco Modern {props.variant}</small>
    <br />
    <small>
      {props.size}
      pt size, {props.line}
      pt line
    </small>
  </div>
))`
  h2 {
    font-size: 1.2em;
    margin: 0;
    color: ${props => props.theme.colours.blue};
  }
  small {
    font-weight: normal;
  }
`;

class TextEditor extends React.Component {
  handleChangeOption(payload) {
    const newState = {
      typography: {
        ...this.props.typography,
        ...payload.typography
      }
    };
    this.props.onChange(newState);
  }

  render() {
    return (
      <Wrapper>
        <ToggleGroup
          label=""
          name="typography"
          value={this.props.typography}
          allowMultiple={true}
          onChange={this.handleChangeOption.bind(this)}
        >
          <Toggle value="headline">
            <Type name="Headline" variant="Bold" size={90} line={99} />
          </Toggle>
          <Toggle value="subheader">
            <Type name="Sub header" variant="Bold" size={30} line={33} />
          </Toggle>
          <Toggle value="body">
            <Type name="Body" variant="Regular" size={20} line={22} />
          </Toggle>
        </ToggleGroup>
      </Wrapper>
    );
  }
}

export default TextEditor;
