import React, { useState } from "react";
import styled from "styled-components";
import StepStepper from "/components/StepStepper";
import { ToggleGroup, Toggle, ToggleSeperator } from "/components/Controls";

import theme from "/shared/theme";

import tescoLogo from "/assets/images/TES_LOGO_REDBLUE_CMYK.svg";
import partnerLogo from "/assets/images/partner_logo.svg";

import lockups from "../assets/images/*.svg";

const TescoLogo = props => <img src={tescoLogo} height="25px" />;
const PartnerLogo = props => <img src={partnerLogo} height="45px" />;

const Wrapper = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const SubNavButton = styled.button`
  border 0;
  height: 60px;
  width: 160px;
  background: ${props => (props.primary ? theme.colours.blue : "none;")};
  color: ${props =>
    props.primary ? theme.colours.white : theme.colours.darkGrey};
`;

const CobrandingSubNav = styled(props => {
  return (
    <div {...props}>
      <SubNavButton
        onClick={() => {
          if (props.step == 1) {
            props.onCancel();
          } else {
            props.setStep(props.step - 1);
          }
        }}
      >
        Back
      </SubNavButton>
      <SubNavButton
        primary={true}
        onClick={() => {
          if (props.step == 2) {
            props.onComplete();
          } else {
            props.setStep(props.step + 1);
          }
        }}
      >
        Next
      </SubNavButton>
    </div>
  );
})`
  display: flex;
  justify-content: space-between;
`;

const CoBrandingDescriptor = styled(props => (
  <div {...props}>
    <img src={lockups[props.type]} height="24px" />
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colours.blue};
  font-weight: normal;
  > * {
    margin: 0 10px;
  }
`;

const CoBrandingSubEditor = props => {
  const [step, setStep] = useState(1);
  return (
    <Wrapper style={{ maxWidth: "80%" }}>
      <StepStepper step={step}>
        <Wrapper canvas={false}>
          <ToggleGroup
            label="Add co-branding"
            name="tescoLed"
            value={props.tescoLed}
            onChange={e => {
              props.onChange({ tescoLed: e.tescoLed });
            }}
          >
            <Toggle radioDot={true} value={true}>
              Tesco led
            </Toggle>
            <Toggle radioDot={true} value={false}>
              Partner/Supplier led
            </Toggle>
          </ToggleGroup>
        </Wrapper>
        <Wrapper canvas={false}>
          {props.tescoLed && (
            <ToggleGroup
              label="Add co-branding"
              name="tescoLedDescriptor"
              value={props.tescoLedDescriptor}
              onChange={e => {
                props.onChange({ tescoLedDescriptor: e.tescoLedDescriptor });
              }}
              maxHeight="400px"
            >
              <ToggleSeperator>Default descriptor</ToggleSeperator>
              <Toggle value="tescoInPartnershipWithPartner">
                <CoBrandingDescriptor type="tescoInPartnershipWithPartner" />
              </Toggle>
              <Toggle value="inPartnershipWithPartner">
                <CoBrandingDescriptor type="inPartnershipWithPartner" />
              </Toggle>
              <ToggleSeperator>Alternative descriptor</ToggleSeperator>
              <Toggle value="withPartner">
                <CoBrandingDescriptor type="withPartner" />
              </Toggle>
              <Toggle value="deliveredInPartnershipWithPartner">
                <CoBrandingDescriptor type="deliveredInPartnershipWithPartner" />
              </Toggle>
              <Toggle value="workingWithPartner">
                <CoBrandingDescriptor type="workingWithPartner" />
              </Toggle>
            </ToggleGroup>
          )}
          {!props.tescoLed && (
            <ToggleGroup
              label="Add co-branding"
              name="partnerLedDescriptor"
              value={props.partnerLedDescriptor}
              onChange={e => {
                props.onChange({
                  partnerLedDescriptor: e.partnerLedDescriptor
                });
              }}
              maxHeight="400px"
            >
              <ToggleSeperator>Tesco as retailer</ToggleSeperator>
              <Toggle value="partnerOnlyAtTesco">
                <CoBrandingDescriptor type="partnerOnlyAtTesco" />
              </Toggle>
              <Toggle value="availableAtTesco">
                <CoBrandingDescriptor type="availableAtTesco" />
              </Toggle>
              <Toggle value="availableAtSelectedTescoStores">
                <CoBrandingDescriptor type="availableAtSelectedTescoStores" />
              </Toggle>
              <Toggle value="availableAtYourLocalTesco">
                <CoBrandingDescriptor type="availableAtYourLocalTesco" />
              </Toggle>
              <ToggleSeperator>Tesco supporting</ToggleSeperator>
              <Toggle value="proudlySponsoredByTesco">
                <CoBrandingDescriptor type="proudlySponsoredByTesco" />
              </Toggle>
              <Toggle value="inAssociationWithTesco">
                <CoBrandingDescriptor type="inAssociationWithTesco" />
              </Toggle>
              <Toggle value="proudlySupportedByTesco">
                <CoBrandingDescriptor type="proudlySupportedByTesco" />
              </Toggle>
            </ToggleGroup>
          )}
        </Wrapper>
      </StepStepper>

      <CobrandingSubNav
        setStep={setStep}
        step={step}
        onCancel={props.onCancel}
        onComplete={props.onComplete}
      />
    </Wrapper>
  );
};

export default CoBrandingSubEditor;
