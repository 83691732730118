import React from "react";
import styled from "styled-components";
import Canvas from "/components/Canvas";
import { Title } from "/components/Title";

const Main = styled.div`
  max-width: 1440px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-grow: 1;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const Half = styled.div`
  width: 50%;
`;

const StepStepper = styled(props => {
  const child = [].concat(props.children)[props.step - 1];
  if (child.props.canvas != undefined && child.props.canvas == true) {
    return (
      <Main {...props}>
        {child.props.title != undefined && <Title>{child.props.title}</Title>}
        <Half>{child}</Half>
        <Half>
          <Canvas
            {...child.props}
            height={298}
            width={210}
            ref={props.canvasRef}
          />
        </Half>
      </Main>
    );
  } else {
    return (
      <Main {...props}>
        {child.props.title != undefined && <Title>{child.props.title}</Title>}
        {child}
      </Main>
    );
  }
})`
  text-align: center;
`;

export default StepStepper;
