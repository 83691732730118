import React from "react";
import styled from "styled-components";
import theme from "/shared/theme";

export const Title = styled.h1`
  color: ${props => theme.colours.blue};
  font-size: 3em;
  text-align: left;
  width: 100%
  &:after {
    content: ".";
    color: ${props => theme.colours.red};
  }
`;
