import React from "react";
import SVG from "svg.js";
import theme from "/shared/theme";

import logo from "/assets/images/TES_LOGO_REDBLUE_CMYK.svg";

import lockups from "../assets/images/*.svg";

const MMPX_RATIO = 3.543307;

class Canvas extends React.Component {
  componentDidMount() {
    this.svg = SVG(this.refs.canvas)
      .size(
        `${this.props.width * MMPX_RATIO}`,
        `${this.props.height * MMPX_RATIO}`
      )
      .viewbox(`0 0 ${this.props.width}mm ${this.props.height}mm`);
    this.draw(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.draw(nextProps);
  }

  drawLogoArea(props) {
    const logoBarHeight = (this.props.width / 12) * 1.5;
    this.svg
      .rect(`${this.props.width}`, `${logoBarHeight}`)
      .move("0", `${this.props.height - logoBarHeight}`)
      .fill(theme.colours.white);
  }

  drawGrid(props) {
    const gridSize = props.width / props.columns;
    const rows = Math.ceil(props.height / gridSize);

    const group = this.svg.group().attr("id", "Grid");

    [...Array(props.columns)].map((_, i) => i + 1).forEach(col => {
      group
        .line(`${col * gridSize}`, "0", `${col * gridSize}`, `${props.height}`)
        .stroke({ color: "#f06", width: 0.5 });
    });

    [...Array(rows)].map((_, i) => i).forEach(row => {
      group
        .line("0mm", `${row * gridSize}`, `${props.width}`, `${row * gridSize}`)
        .stroke({ color: "#f06", width: 0.5 });
    });
  }

  async drawLogo(props) {
    const lockupName = props.tescoLed
      ? props.tescoLedDescriptor
      : props.partnerLedDescriptor;

    const logoSVG = await this.fetchLogo(
      lockups[lockupName] || lockups["tesco"]
    );
    this.svg.svg(logoSVG);

    const drawnLogo = this.svg.select("svg");
    drawnLogo.size(null, `${this.props.width / 12 / 3}`);

    drawnLogo.move(
      `${this.props.width - drawnLogo.bbox().w - this.props.width / 12}`,
      `${this.props.height - this.props.width / 12}`
    );
  }

  drawText(props) {
    const gridSize = props.width / props.columns;
    const twelveGridSize = props.width / 12;
    const headlineText = "Lorem\nipsum";
    const subheadingText =
      "Lorem ipsum dolor sit amet\nconsectetur adipiscing.";
    const bodyText = `Lorem ipsum dolor sit amet consectetur\n
adipiscing elit, sed eiusmod tempor\n
incididunt ut labore et dlore magna\n
aliqua. Ut enim ad minim veniam, quis.`;

    if (props.typography.headline) {
      this.svg
        .text(headlineText)
        .fill(theme.colours.white)
        .font({
          family: "Tesco Modern",
          weight: "bold",
          size: 90 / MMPX_RATIO,
          anchor: "left",
          leading: "99pt"
        })
        .leading(1.1)
        .move(`${gridSize}`, `${twelveGridSize}`);
    }

    if (props.typography.subheader) {
      this.svg
        .text(subheadingText)
        .fill(theme.colours.white)
        .font({
          family: "Tesco Modern",
          weight: "bold",
          size: 30 / MMPX_RATIO,
          anchor: "left"
        })
        .leading(1.1)
        .move(`${gridSize}`, `${twelveGridSize * 5}`);
    }

    if (props.typography.body) {
      this.svg
        .text(bodyText)
        .fill(theme.colours.white)
        .font({
          family: "Tesco Modern",
          weight: "normal",
          size: 20 / MMPX_RATIO,
          anchor: "left"
        })
        .leading(1.1)
        .move(`${gridSize}`, `${twelveGridSize * 7}`);
    }
  }

  draw(props) {
    const gridSize = props.width / props.columns;
    this.svg.clear();
    this.svg
      .rect(`${this.props.width}mm`, `${this.props.height}mm`)
      .fill(theme.colours.blue);

    this.drawText(props);
    this.drawLogoArea(props);
    this.drawLogo(props);
    this.drawGrid(props);
  }

  render() {
    return <div style={{ zoom: 0.5 }} ref="canvas" />;
  }

  async fetchLogo(url) {
    try {
      const response = await fetch(url);
      const body = await response.text();
      return body;
    } catch (e) {
      return false;
    }
  }
}

export default Canvas;
