import React from "react";
import styled from "styled-components";

export const Option = styled(props => <div {...props}>{props.title}</div>)`
  background: ${props =>
    props.image != undefined
      ? `url(${props.image})`
      : props.theme.colours.grey};
  background-size: cover;
  border ${props => {
    return props.active ? "5px blue solid;" : "0;";
  }};
  box-sizing: border-box;
  display: flex;
  flex-direction: columns;
  justify-content: center;
  align-items: center;
  font-family: "SuperSans-SemiBold", sans-serif;
  font-size: 28px;
  height: 200px;
  margin: 20px;
  width: 200px;
`;

export const OptionSelector = styled(props => {
  const handlers = {
    onClick: evt => {
      let payload = { nextEnabled: true };
      payload[props.name] = evt.currentTarget.dataset.value;
      props.onChange(payload);
    }
  };
  return (
    <div {...props}>
      {props.children.map((child, index) =>
        React.cloneElement(
          child,
          Object.assign({}, handlers, {
            key: index,
            active:
              props.value == child.props["data-value"].toString()
                ? "true"
                : undefined
          })
        )
      )}
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
`;
