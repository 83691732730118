import "babel-polyfill";

import React from "react";
import styled, { ThemeProvider } from "styled-components";
import "normalize.css";
import GlobalStyles from "/shared/globalStyles";
import download from "downloadjs";
import theme from "/shared/theme";

import StepStepper from "/components/StepStepper";
import SizeEditor from "/containers/SizeEditor";
import GridEditor from "/containers/GridEditor";
import TextEditor from "/containers/TextEditor";
import LogoEditor from "/containers/LogoEditor";
import ExampleBrowser from "/containers/ExampleBrowser";

import { OptionSelector, Option } from "/components/OptionSelector";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  height: 100vh;
`;

const Nav = styled.div`
  background: ${props => props.theme.colours.white};
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
`;

const NavButton = styled.button`
  background: ${props =>
    props.primary ? props.theme.colours.blue : props.theme.colours.white};
  color: ${props =>
    props.primary ? props.theme.colours.white : props.theme.colours.blue};
  border: 0;
  font-size: 20px;
  font-weight: bolder;
  height: 120px;
  min-width: 220px;
`;

const ExportButton = styled(NavButton)`
  background: ${props => props.theme.colours.red};
  color: ${props => props.theme.colours.white};
`;

const NavStatus = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      currentStep: 1,
      numberOfSteps: 4,
      columns: 12,
      preset: false,
      typography: {
        headline: true,
        subheader: true,
        body: true
      },
      tescoOnly: true,
      tescoLed: true,
      nextEnabled: true
    };
  }

  handleOptionChange(payload) {
    this.setState(payload);
  }

  handleNavigate(evtOrStep) {
    let step;
    if (Number.isInteger(evtOrStep)) {
      step = evtOrStep;
    } else {
      step = parseInt(evtOrStep.currentTarget.dataset.target, 10);
    }
    if (step > 0) {
      this.setState({ currentStep: step, showExport: false });
    }
  }

  handleExport() {
    const svgString = this.canvasRef.current.svg.svg();
    download(svgString, "tesco-template.svg", "image/svg+xml");
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <GlobalStyles />
          <StepStepper step={this.state.currentStep} canvasRef={this.canvasRef}>
            {/*<SizeEditor
              {...this.state}
              title="Select a size"
              onChange={this.handleOptionChange.bind(this)}
              canvas={false}
              /> */}
            <GridEditor
              {...this.state}
              title="Define a grid"
              onChange={this.handleOptionChange.bind(this)}
              canvas={true}
            />
            <LogoEditor
              {...this.state}
              title="Customise the logo panel"
              onChange={this.handleOptionChange.bind(this)}
              onComplete={() => this.handleNavigate(this.state.currentStep + 1)}
              canvas={true}
            />
            <TextEditor
              {...this.state}
              title="Add type styles"
              onChange={this.handleOptionChange.bind(this)}
              canvas={true}
            />
            <ExampleBrowser
              {...this.state}
              title="Your template is ready"
              canvas={true}
            />
          </StepStepper>
          <Nav>
            <NavButton
              data-target={this.state.currentStep - 1}
              onClick={this.handleNavigate.bind(this)}
            >
              Back
            </NavButton>
            <NavStatus>
              Step {this.state.currentStep} of {this.state.numberOfSteps}
            </NavStatus>
            {this.state.currentStep == this.state.numberOfSteps ? (
              <ExportButton
                primary={true}
                onClick={this.handleExport.bind(this)}
              >
                Export
              </ExportButton>
            ) : (
              <NavButton
                primary={true}
                data-target={this.state.currentStep + 1}
                disabled={!this.state.nextEnabled}
                onClick={this.handleNavigate.bind(this)}
              >
                Next
              </NavButton>
            )}
          </Nav>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

export default App;
