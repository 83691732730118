import React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const Examples = styled.div`
  color: ${props => props.theme.colours.darkGrey};
  text-align: left;
  width: 50%;
  h2 {
    width: 100%;
  }
`;

class ExampleBrowser extends React.Component {
  handleChangeOption(payload) {
    const newState = {
      typography: {
        ...this.props.typography,
        ...payload.typography
      }
    };
    this.props.onChange(newState);
  }

  render() {
    return (
      <Wrapper>
        <Examples>
          <h2>Here are your examples</h2>
        </Examples>
      </Wrapper>
    );
  }
}

export default ExampleBrowser;
