import React from "react";
import styled from "styled-components";
import theme from "/shared/theme";

export const ControlLabel = styled.label`
  color: ${props => props.theme.colours.darkGrey};
  display: block;
  font-family: "Tesco Modern", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 1.5em;
  padding: 10px 0;
`;

export const Control = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding-left: 10px;
  box-sizing: border-box;
  font-family: "Tesco Modern", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: ${props => props.theme.colours.darkGrey};
  margin-bottom: 20px;
  height: 80px;
  width: 100%;
  max-width: 480px;
  user-select: none;
  background: ${props => props.theme.colours.white};
  border-style: solid;
  border-width: 2px;
  border-color ${props => {
    return props.active
      ? props.theme.colours.blue
      : props.theme.colours.medGrey;
  }};
`;

const InnerDot = styled.div`
  width: 19px;
  height: 19px;
  background: ${props => props.theme.colours.blue};
  border-radius: 100%;
`;

const RadioDot = styled(props => (
  <div {...props}>{props.active && <InnerDot />}</div>
))`
border-radius: 100%
height: 32px;
width: 32px;
background: ${props => props.theme.colours.grey};
border-style: solid;
border-width: 2px;
border-color: ${props =>
  props.active ? props.theme.colours.blue : props.theme.colours.medDarkGrey};
display: flex;
align-items: center;
justify-content: center;
margin-right: 24px;
`;

export const TextInput = styled(props => (
  <Control>
    <ControlLabel>{props.label}</ControlLabel>
    <input type="text" {...props} />
  </Control>
))`
  background: ${props => props.theme.colours.lighterGrey};
  border: 0;
  font-family: "SuperSans-SemiBold", sans-serif;
  font-size: 18px;
  padding: 10px;
`;

export const TextArea = styled(props => (
  <Control>
    <ControlLabel>{props.label}</ControlLabel>
    <textarea {...props} />
  </Control>
))`
  background: ${props => props.theme.colours.lighterGrey};
  border: 0;
  font-family: "SuperSans-SemiBold", sans-serif;
  font-size: 18px;
  padding: 10px;
`;

export class Toggle extends React.Component {
  static defaultProps = {
    component: ToggleWrapper
  };

  handleClick(evt) {
    const payload = {};
    if (this.props.allowMultiple) {
      const subPayload = {};
      subPayload[this.props.value] = !this.props.active;
      payload[this.props.name] = subPayload;
    } else {
      payload[this.props.name] = this.props.value;
    }
    this.props.onChange(payload);
  }

  render() {
    const { component: Tag } = this.props;
    return (
      <Tag {...this.props} onClick={this.handleClick.bind(this)}>
        {this.props.radioDot && <RadioDot active={this.props.active} />}
        {this.props.children}
      </Tag>
    );
  }
}

export const ToggleGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: ${props => props.maxHeight}
  overflow: scroll;
  overflow: scrollbars-vertical;
`;

export class ToggleGroup extends React.Component {
  handleChange(payload) {
    this.props.onChange(payload);
  }

  render() {
    return (
      <Control>
        <ControlLabel>{this.props.label}</ControlLabel>
        <ToggleGroupWrapper maxHeight={this.props.maxHeight || "initial"}>
          {[].concat(this.props.children).map((child, index) => {
            return React.cloneElement(child, {
              name: this.props.name,
              key: index,
              onChange: this.handleChange.bind(this),
              allowMultiple: this.props.allowMultiple,
              active: this.props.allowMultiple
                ? this.props.value[child.props.value]
                : this.props.value == child.props.value
            });
          })}
        </ToggleGroupWrapper>
      </Control>
    );
  }
}

export const ToggleSeperator = styled.h4`
  color: ${theme.colours.darkGrey};
  text-transform: uppercase;
  font-weight: normal;
  width: 100%;
  text-align: left;
`;
