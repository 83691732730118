import React from "react";
import styled from "styled-components";
import theme from "/shared/theme";
import { ToggleGroup, Toggle, ToggleSeperator } from "/components/Controls";
import StepStepper from "/components/StepStepper";
import CoBrandingSubEditor from "/components/CoBrandingSubEditor";

const Wrapper = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

class LogoEditor extends React.Component {
  handleChangeOption(payload) {
    const newState = {
      ...this.props.tescoOnly,
      ...payload
    };
    this.props.onChange(newState);
  }

  handleCancelCoBrand() {
    this.handleChangeOption({ tescoOnly: true });
  }

  render() {
    if (this.props.tescoOnly) {
      return (
        <Wrapper>
          <ToggleGroup
            label="Default logo panel"
            name="tescoOnly"
            value={this.props.tescoOnly}
            onChange={e => {
              this.handleChangeOption({ tescoOnly: e.tescoOnly });
            }}
          >
            <Toggle radioDot={true} value={true}>
              Tesco Only
            </Toggle>
            <Toggle value={false}>Add co-branding</Toggle>
          </ToggleGroup>
        </Wrapper>
      );
    } else {
      return (
        <CoBrandingSubEditor
          {...this.props}
          onCancel={this.handleCancelCoBrand.bind(this)}
          onChange={this.handleChangeOption.bind(this)}
          onComplete={this.props.onComplete.bind(this)}
        />
      );
    }
  }
}

export default LogoEditor;
