import React from "react";
import styled from "styled-components";
import { ToggleGroup, Toggle, ToggleSeperator } from "/components/Controls";

const Wrapper = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

class GridEditor extends React.Component {
  handleChangeOption(payload) {
    const newState = {
      ...this.props.columns,
      ...payload
    };
    this.props.onChange(newState);
  }

  render() {
    return (
      <Wrapper>
        <ToggleGroup
          label="Number of Columns"
          name="columns"
          value={this.props.columns}
          onChange={e => {
            this.handleChangeOption({ columns: parseInt(e.columns, 10) });
          }}
        >
          <ToggleSeperator>Recommended</ToggleSeperator>
          <Toggle radioDot={true} value="12">
            12 Columns
          </Toggle>
          <ToggleSeperator>Alternative</ToggleSeperator>
          <Toggle radioDot={true} value="10">
            10 Columns
          </Toggle>
          <Toggle radioDot={true} value="8">
            8 Columns
          </Toggle>
          <Toggle radioDot={true} value="6">
            6 Columns
          </Toggle>
        </ToggleGroup>
      </Wrapper>
    );
  }
}

export default GridEditor;
