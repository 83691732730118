import React from "react";
import styled from "styled-components";
import { ToggleGroup, Toggle } from "/components/Controls";

const Wrapper = styled.div`
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Preset = styled(props => (
  <div {...props} key={props.value}>
    {props.children}
  </div>
))`
  background: red;
  width: 192px;
  height: 192px;
`;

class SizeEditor extends React.Component {
  static presets = {
    a4: {
      width: 210,
      height: 290
    },
    a3: {
      width: 420,
      height: 580
    }
  };

  handleChangeOption(payload) {
    const newState = {
      ...this.props.preset,
      ...payload
    };
    this.props.onChange(newState);
  }

  render() {
    return (
      <Wrapper>
        <ToggleGroup
          label="Select from popular print formats"
          name="preset"
          value={this.props.preset}
          onChange={e => {
            this.handleChangeOption({ preset: e.preset });
          }}
        >
          {Object.keys(SizeEditor.presets).map(preset => (
            <Toggle value={preset} component={Preset}>
              {preset.toUpperCase()}
            </Toggle>
          ))}
        </ToggleGroup>
      </Wrapper>
    );
  }
}

export default SizeEditor;
